import { RichTextInput } from "ra-input-rich-text";
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  useTranslate,
  FormDataConsumer,
  useEditController,
  maxLength,
  useGetList,
  SortPayload,
  useTheme,
} from "react-admin";
import { checkTransformationsOrder, scheduleValidation } from "..";
import IntegrationTarget from "./TargetIntegration";
import { S3DepositSource } from "@/Components/Models/Configuration/SourceS3Deposit";
import { StandardSource } from "@/Components/Models/Configuration/SourceStandard";
import TargetDelay from "./TargetDelay";
import { useRedirectWithParams } from "@/Tools/hooks";
import { clearStyleFromHtml, validateFilename } from "@/Tools/helpers";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  DEFAULT_POLLING_TYPE,
  DEFAULT_S3_DEPOSIT_CONNECTION,
  DEFAULT_TRANSFERT_MODE,
  DEFAULT_TRANSFERT_TYPE,
} from "./constants";
import { formatIntegration } from "./utils";

const EditForm = (props: any) => {
  const { record, resource } = useEditController(props);
  const [useS3Deposit, setS3Deposit] = useState(record?.source.use_s3_deposit);
  const s3DepositConnection = useRef("");
  const redirect = useRedirectWithParams(resource, [["from", "edit"]]);
  const translate = useTranslate();
  const [theme] = useTheme();
  const sortable: SortPayload = { field: "name", order: "ASC" };

  const { data: connections } = useGetList("connection", {
    filter: { name: DEFAULT_S3_DEPOSIT_CONNECTION },
    pagination: { page: 1, perPage: 1 },
  });
  useEffect(() => {
    if (connections && connections.length > 0) {
      s3DepositConnection.current = connections[0].id;
    }
  }, [connections]);

  const cleanFields = (data: any) => {
    const { id, name, code, created_at, created_by, updated_at, updated_by, ...rest } = data;
    const useSourceTransformations = rest.source.transformations ? true : false;
    const useSourceMoveAfter = rest.source.transfert_type === "MOVE_AFTER" ? true : false;
    if (!useSourceTransformations) delete rest["source"]["transformations"];
    if (!useSourceMoveAfter) delete rest["source"]["transfert_path"];

    if (!rest.source.use_s3_deposit) {
      return {
        ...rest,
        source: {
          ...rest.source,
          use_s3_deposit: false,
        },
        targets: rest.targets.map((target: any) => {
          const useTargetTransformations = target.transformations ? true : false;
          const useIntegration = target.integration.use_integration ? true : false;
          if (!useTargetTransformations) delete target["transformations"];
          return {
            ...target,
            integration: {
              ...(useIntegration
                ? formatIntegration(target.integration)
                : { use_integration: false }),
            },
          };
        }),
      };
    }

    const emptyFilters = {
      unitType: "",
      unit: 0,
    };
    return {
      ...rest,
      source: {
        ...rest.source,
        use_s3_deposit: rest.source.use_s3_deposit,
        connection: s3DepositConnection.current,
        path: "",
        regex: "",
        transfert_type: DEFAULT_TRANSFERT_TYPE,
        transfert_mode: DEFAULT_TRANSFERT_MODE,
        nb_files_per_transfert: "-1",
        polling: DEFAULT_POLLING_TYPE,
        filters_enabled: false,
        filters: emptyFilters,
      },
      targets: rest.targets.map((target: any) => {
        const useTargetTransformations = target.transformations ? true : false;
        const useIntegration = target.integration.use_integration ? true : false;
        if (!useTargetTransformations) delete target["transformations"];
        return {
          ...target,
          integration: {
            ...(useIntegration
              ? formatIntegration(target.integration)
              : { use_integration: false }),
          },
        };
      }),
    };
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setS3Deposit(event.target.checked);
  };

  return (
    <Edit {...props} mutationMode="pessimistic" onSuccess={redirect} transform={cleanFields}>
      <TabbedForm warnWhenUnsavedChanges>
        <FormTab label="Summary">
          <TextInput source="name" readOnly />
          <TextInput source="code" readOnly />
          <span aria-test="configuration-edit-description">
            <RichTextInput
              source="description"
              validate={[required()]}
              parse={clearStyleFromHtml}
            />
          </span>
          <ReferenceInput source="criticity" reference="criticityType">
            <SelectInput
              aria-test="configuration-edit-criticity"
              optionText="name"
              validate={[required()]}
            />
          </ReferenceInput>
          <TextInput
            aria-test="configuration-edit-requestor"
            source="requestor"
            validate={[maxLength(100)]}
            parse={(value: any) => value}
          />
          <TextInput
            aria-test="configuration-edit-demandNumber"
            source="demand_number"
            validate={[maxLength(100)]}
            parse={(value: any) => value}
          />
          <BooleanInput
            aria-test="configuration-edit-enabled"
            source="enabled"
            defaultValue={true}
          />
          <BooleanInput
            aria-test="configuration-edit-direct"
            source="direct"
            defaultValue={false}
          />
        </FormTab>
        <FormTab label="Source">
          <BooleanInput
            aria-test="configuration-edit-source-s3deposit"
            source="source.use_s3_deposit"
            label={"Use S3 Deposit"}
            onChange={handleChange}
          />
          {useS3Deposit ? (
            <S3DepositSource s3DepositConnection={s3DepositConnection.current} />
          ) : (
            <StandardSource />
          )}
        </FormTab>
        <FormTab label="Target">
          <ArrayInput source="targets">
            <SimpleFormIterator
              className={`${theme === "dark" ? "darkTheme" : ""} multiTargetConfig`}
            >
              <span aria-test="configuration-edit-target-description">
                <RichTextInput
                  source="description"
                  validate={[required()]}
                  label={translate("resources.configuration.fields.target.description")}
                />
              </span>
              <ReferenceInput
                source="connection"
                reference="connection"
                label={translate("resources.configuration.fields.target.connection")}
                sort={sortable}
              >
                <SelectInput
                  aria-test="configuration-edit-target-connection"
                  optionText="name"
                  validate={[required()]}
                />
              </ReferenceInput>
              <BooleanInput
                aria-test="configuration-edit-target-enabled"
                source="enabled"
                defaultValue={true}
                label={translate("resources.configuration.fields.target.enabled")}
              />
              <BooleanInput
                aria-test="configuration-edit-target-errorIfExists"
                source="error_if_file_exists"
                defaultValue={false}
                label={translate("resources.configuration.fields.target.error_if_file_exists")}
              />
              <TextInput
                aria-test="configuration-edit-target-path"
                source="path"
                validate={[required()]}
                label={translate("resources.configuration.fields.target.path")}
              />
              <ReferenceInput
                source="application"
                reference="application"
                label={translate("resources.configuration.fields.target.application")}
                sort={sortable}
              >
                <SelectInput
                  aria-test="configuration-edit-target-application"
                  optionText="name"
                  validate={[required()]}
                />
              </ReferenceInput>
              <TextInput
                aria-test="configuration-edit-target-wispGroup"
                label={translate("resources.configuration.fields.target.wisp_group")}
                source="wisp_group"
              />
              <TextInput
                aria-test="configuration-edit-target-job"
                source="job"
                parse={(value: any) => value}
                label={translate("resources.configuration.fields.target.job")}
              />
              <TextInput
                aria-test="configuration-edit-target-information"
                source="information"
                parse={(value: any) => value}
                label={translate("resources.configuration.fields.target.information")}
              />
              <ReferenceInput
                source="pattern_file_name"
                reference="fileNamePattern"
                label={translate("resources.configuration.fields.target.pattern_file_name")}
              >
                <SelectInput
                  aria-test="configuration-edit-target-patternFileName"
                  optionText="name"
                  validate={[required()]}
                />
              </ReferenceInput>
              <FormDataConsumer>
                {({ scopedFormData }) =>
                  scopedFormData && scopedFormData.pattern_file_name === "TRIM" ? (
                    <TextInput
                      aria-test="configuration-edit-target-trimCharacter"
                      source="trim_character"
                      validate={[required()]}
                      label={translate("resources.fileNamePattern.fields.trim_character")}
                    />
                  ) : null
                }
              </FormDataConsumer>
              <FormDataConsumer>
                {({ scopedFormData }) =>
                  scopedFormData &&
                  (scopedFormData.pattern_file_name === "RENAME" ||
                    scopedFormData.pattern_file_name === "RENAME_CHRONO" ||
                    scopedFormData.pattern_file_name === "RENAME_NOW") ? (
                    <TextInput
                      aria-test="configuration-edit-target-newName"
                      source="new_name"
                      validate={[required(), validateFilename]}
                      label={translate("resources.fileNamePattern.fields.new_name")}
                    />
                  ) : null
                }
              </FormDataConsumer>
              <NumberInput
                aria-test="configuration-edit-target-nbFiles"
                source="nb_files_per_transfert"
                defaultValue={-1}
                label={translate("resources.configuration.fields.target.nb_files_per_transfert")}
              />
              <TargetDelay
                source="delay"
                label={translate("resources.configuration.fields.target.delay")}
              />
              <FormDataConsumer>
                {({ formData }) =>
                  formData && formData.direct === false ? (
                    <TextInput
                      aria-test="configuration-edit-target-scheduleExp"
                      source="schedule_expression"
                      validate={[required(), scheduleValidation]}
                      label={translate("resources.configuration.fields.source.schedule_expression")}
                    />
                  ) : null
                }
              </FormDataConsumer>

              <IntegrationTarget
                source="integration"
                label={translate("resources.configuration.fields.target.integration")}
              />

              <ArrayInput
                source="transformations"
                label={translate("resources.configuration.fields.target.transformations")}
                validate={[checkTransformationsOrder]}
              >
                <SimpleFormIterator>
                  <ReferenceInput
                    source=""
                    reference="transformationType"
                    label="resources.configuration.fields.transformation"
                  >
                    <SelectInput
                      aria-test="configuration-edit-target-transformation"
                      optionText="name"
                      validate={[required()]}
                    />
                  </ReferenceInput>
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default EditForm;
